// src/components/Position.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, TextField, Tooltip, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from 'styled-components';
import axios from 'axios';
import TokenDetails from './TokenDetails';
import { ethers } from 'ethers';

const PositionCard = styled(Card)`
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Label = styled(Typography)`
  color: #666;
  font-weight: 500;
`;

const Value = styled(Typography)`
  font-weight: 700;
`;

const RangeIndicator = styled(Typography)`
  font-weight: 700;
  color: ${props => props.inRange ? '#4caf50' : '#f44336'};
`;

const StyledTextField = styled(TextField)`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

function tickToPrice(tick, decimals0, decimals1) {
  return (Math.pow(1.0001, tick)) / (Math.pow(10, decimals1 - decimals0));
}

function isStablecoin(symbol){
  const array=['USDC', 'USDT', 'DAI'];
  return array.includes(symbol);
}

function formatToSignificantFigures(value, significantFigures = 4) {
  if (value === 0) return "0.0000";
  const exponent = Math.floor(Math.log10(Math.abs(value)));
  const factor = Math.pow(10, significantFigures - 1 - exponent);
  return (Math.round(value * factor) / factor).toFixed(Math.max(0, significantFigures - 1 - exponent));
}

const Position = ({ position, id, name, tickLower, tickUpper, totalUSD, currentPrice, amount0, amount1, feeAPR, isHedgeable, hedgeStatus, fetchHedges, network, provider }) => {
  const {
    liquidity,
    pool,
    token0Symbol,
    token1Symbol,
    token0addr,
    token1addr,
    token0Decimals,
    token1Decimals,
    isShow,
    inRange
  } = position;

  const networkConfig = network;

  const [hedgeSchema, setHedgeSchema] = useState([
    {
      "fr": 0.45,
      "sl": 0
    },
    {
      "fr": 0.20,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0.20,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0.15,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    }
  ]);

  const [showHedge, setShowHedge] = useState(false);
  const priceLower = tickToPrice(tickLower, token0Decimals, token1Decimals);
  const priceUpper = tickToPrice(tickUpper, token0Decimals, token1Decimals);
  const [ButtonName, setButtonName] = useState(hedgeStatus!=="" ? 'Stop Hedge' : 'Hedge'); 


  async function approveAutoExitContract(networkConfig, signer) {

    if (!networkConfig.uniswapNFTManagerAddress || !ethers.utils.isAddress(networkConfig.uniswapNFTManagerAddress)) {
        throw new Error("Invalid or missing Uniswap NFT Manager Address.");
    }

    if (!networkConfig.AutoExitAddress || !ethers.utils.isAddress(networkConfig.AutoExitAddress)) {
        throw new Error("Invalid or missing AutoExit Address.");
    }

    const contract = new ethers.Contract(
        networkConfig.uniswapNFTManagerAddress,
        [
            'function setApprovalForAll(address operator, bool approved) public'
        ],
        signer
    );

    await contract.setApprovalForAll(networkConfig.AutoExitAddress, true);
    console.log('Approved successfully');
}


async function configureToken(
  networkConfig,
  signer,
  tokenId,
  token0Symbol,
  tickLower,
  tickUpper
) {
    const token0IsStable = isStablecoin(token0Symbol);
    const token0Swap = !token0IsStable;
    const token1Swap = token0IsStable;

    const tickLowerInt = parseInt(tickLower, 10);
    const tickUpperInt = parseInt(tickUpper, 10);

    if (tickLowerInt < -8388608 || tickLowerInt > 8388607) {
        throw new Error("tickLower is out of int24 range");
    }
    if (tickUpperInt < -8388608 || tickUpperInt > 8388607) {
        throw new Error("tickUpper is out of int24 range");
    }

    const isActive = true;
    if (isActive && tickLowerInt >= tickUpperInt) {
        throw new Error("InvalidConfig: tickLower must be less than tickUpper when active");
    }

    const config = [
        isActive,
        token0Swap,
        token1Swap,
        tickLowerInt,
        tickUpperInt,
        ethers.BigNumber.from("368934881474191032"),
        ethers.BigNumber.from("368934881474191032"),
        false,
        ethers.BigNumber.from("184467440737095516"),
    ];

    //console.log(config);

    const contract = new ethers.Contract(
        networkConfig.AutoExitAddress,
        [
            'function configToken(uint256 tokenId, (bool,bool,bool,int24,int24,uint64,uint64,bool,uint64) config) external',
        ],
        signer
    );

    try {
          const data = contract.interface.encodeFunctionData("configToken", [
            tokenId,
            config
        ]);
        //console.log(data);
    
        //console.log("Submitting transaction...");
        const tx = await contract.configToken(tokenId, config, { gasLimit: 1003867 });
        await tx.wait();
        //console.log(`Transaction confirmed with hash: ${tx.hash}`);
    } catch (error) {
        console.error("Error configuring token:", error.message);

        if (error?.data?.message) {
            console.error("Revert reason:", error.data.message);
        }

        if (error.message.includes("Unauthorized")) {
            throw new Error("You are not the owner of the token.");
        } else if (error.message.includes("InvalidConfig")) {
            throw new Error("tickLower must be less than tickUpper when active.");
        }

        throw error;
    }
  }
  
  const handleStopHedge = async (positionId) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        "https://api.divedao.io/api/stop_hedging",
        {
          chain: networkConfig.chain,
          position_id: Number(positionId),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        alert("Hedge stopped successfully!");
        setButtonName("Hedge");
        fetchHedges(); // Обновить статус хеджа
      } else {
        alert("Failed to stop hedge.");
        fetchHedges();
      }
    } catch (error) {
      console.error("Error stopping hedge:", error);
    }
  };
  
  const handleApproveHedge = async (positionId) => {
    try {
      const signer = provider.getSigner();
      await approveAutoExitContract(networkConfig, signer);
      await configureToken(
        networkConfig,
        signer,
        positionId,
        token0Symbol,
        tickLower,
        tickUpper
      );
  
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        "https://api.divedao.io/api/hedge",
        {
          chain: networkConfig.chain,
          position_id: Number(positionId),
          schema: hedgeSchema,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        alert("Hedge approved successfully!");
        fetchHedges();
      } else {
        alert("Failed to approve hedge.");
      }
    } catch (error) {
      console.error("Error approving hedge:", error);
    }
  };

  const handleButtonState = (status) => {
    switch (status) {
      case "starting":
        return { text: "Hedging...", disabled: true };
      case "active":
        return { text: "Stop Hedge", disabled: false };
      case "stopping":
        return { text: "Stopping...", disabled: true };
      case "stopped":
        return { text: "Hedge", disabled: false };
      default:
        return { text: "Hedge", disabled: false };
    }
  };

  return (
    isShow === 1 && (
    <PositionCard>
      <CardContent>
        <InfoRow>
          <Label variant="subtitle1">Token Pair:</Label>
          <a href={`https://app.uniswap.org/pools/${id}?chain=${network.chain}`}  target="_blank" variant="subtitle1">{name +" "+ (position.fee/10000) + " %" }</a>
        </InfoRow>
        <InfoRow>
          <TokenDetails tokenId={token0addr} symbol={token0Symbol} network={network} />
          <Value variant="subtitle1">{amount0}</Value>
        </InfoRow>
        <InfoRow>
          <TokenDetails tokenId={token1addr} symbol={token1Symbol} network={network} />
          <Value variant="subtitle1">{amount1}</Value>
        </InfoRow>
        
        <InfoRow>
          <Label variant="subtitle1">Range Status:</Label>
          <RangeIndicator variant="subtitle1" inRange={inRange}>
            {inRange ? '🟢 In Range' : '🔴 Out of Range'}
          </RangeIndicator>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Price Range:</Label>
          <Value variant="subtitle1">
            $ {formatToSignificantFigures(priceLower)} - $ {formatToSignificantFigures(priceUpper)}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Current Price:</Label>
          <Value variant="subtitle1">
            $ {formatToSignificantFigures(currentPrice)}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Position Size:</Label>
          <Value variant="subtitle1">
            $ {formatToSignificantFigures(totalUSD)}
          </Value>
        </InfoRow>

        {isHedgeable ? (
          <div>
            <Tooltip title={hedgeStatus === "active" ? "Stop the hedge" : "Approve the hedge"} arrow>
              <Button
                variant="contained"
                color={hedgeStatus === "active" ? "secondary" : "primary"}
                onClick={() => {
                  if (hedgeStatus === "active") {
                    handleStopHedge(id);
                  } else {
                    handleApproveHedge(id);
                  }
                }}
                disabled={handleButtonState(hedgeStatus).disabled}
              >
                {handleButtonState(hedgeStatus).text}
              </Button>
            </Tooltip>
            {hedgeStatus === "stopped" && position.reason && (
              <Typography variant="body2" style={{ color: "orange", marginTop: 10 }}>
                {position.reason}
              </Typography>
            )}
            <IconButton
              onClick={() => setShowHedge(!showHedge)}
              style={{ marginLeft: 10 }}
            >
              <SettingsIcon />
            </IconButton>
            {showHedge && (
              <div>
                <StyledTextField
                  multiline
                  rows={8}
                  variant="outlined"
                  value={JSON.stringify(hedgeSchema, null, 2)}
                  onChange={(e) => setHedgeSchema(JSON.parse(e.target.value))}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowHedge(false)}
                  style={{ marginTop: 10 }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Cannot hedge this position.
            </Typography>
          )}


      </CardContent>
    </PositionCard>
    )
  );
};

export default Position;
