// src/App.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import WalletModal from './components/WalletModal';
import SettingsModal from './components/SettingsModal';
import PositionInfo from './components/PositionInfo';
import axios from 'axios';
import { ethers } from 'ethers';
import { SiweMessage } from 'siwe';

const AppContainer = styled.div`
  font-family: 'Arial', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const MainContent = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
`;

const supportedNetworks = [
  { chainId: 42161, name: 'Arbitrum One' },
  { chainId: 10, name: 'Optimism' },
  { chainId: 137, name: 'Polygon' }
];

const getNetworkConfig = (chainId) => {
  switch (chainId) {
    case 137:
      return {
        uniswapFactoryAddress: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
        uniswapNFTManagerAddress: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
        AutoExitAddress: "0xCe98E8973102bD64e866bc67E40bA39f1fd1239e",
        url: "https://polygonscan.com/address/",
        chain: "polygon"
      };

    case 10:
      return {
        uniswapFactoryAddress: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
        uniswapNFTManagerAddress: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
        AutoExitAddress: "0x29C08ec5584286E6Fa42e85c86F49b83a1A0eA0b",
        url:"https://optimistic.etherscan.io/address/",
        chain: "optimism"
      };
    case 42161:
      return {
        uniswapFactoryAddress: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
        uniswapNFTManagerAddress: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
        AutoExitAddress: "0x6c6707F5E24B916BB3e45C5822466a88b315F5Ec",
        url:"https://arbiscan.io/address/",
        chain: "arbitrum"
      };
    default:
      throw new Error('Unsupported network');
  }
};

function App() {
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [supportedAssets, setSupportedAssets] = useState([]);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [cexName, setCexName] = useState('');
  const [cexStatus, setCexStatus] = useState('');
  const [currentNetwork, setCurrentNetwork] = useState(supportedNetworks[0].chainId);
  const [networkConfig, setNetworkConfig] = useState(getNetworkConfig(currentNetwork));
  const [currentCexName, setCurrentCexName] = useState('');

  axios.interceptors.response.use(
    response => response,
    async error => {
      if (error.response && error.response.status === 401) {
        if (!isConnected) {
          localStorage.removeItem('authToken');
          setWalletConnected(false);
          setIsConnected(false);
          setWalletAddress('');
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const checkIfConnected = async () => {
      const savedAddress = localStorage.getItem('walletAddress');
      const token = localStorage.getItem('authToken');
      if (savedAddress && token) {
        console.log('I am here');
        try {
          setWalletConnected(true);
          setWalletAddress(savedAddress);
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const network = await provider.getNetwork();
          setCurrentNetwork(network.chainId);
          setNetworkConfig(getNetworkConfig(network.chainId));
          await fetchSupportedAssets();
          await fetchCexStatus();
          setIsConnected(true);
        } catch (error) {
          localStorage.removeItem('authToken');
          setWalletConnected(false);
          setIsConnected(false);
          setWalletAddress('');
        }
      }
    };

    checkIfConnected();

    // слушаем изменения аккаунта и сети
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
          setWalletConnected(true);
          localStorage.setItem('walletAddress', accounts[0]);
        } else {
          setWalletAddress('');
          setWalletConnected(false);
          localStorage.removeItem('walletAddress');
        }
      });

      window.ethereum.on('chainChanged', async (chainId) => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        setCurrentNetwork(network.chainId);
        setNetworkConfig(getNetworkConfig(network.chainId));
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', () => {});
        window.ethereum.removeListener('chainChanged', () => {});
      }
    };
  }, [walletAddress]);

  useEffect(() => {
    const initNetwork = async () => {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        handleNetworkChange(network.chainId);

        window.ethereum.on('chainChanged', (chainIdHex) => {
          const chainId = parseInt(chainIdHex, 16);
          handleNetworkChange(chainId);
        });
      } else {
        console.log('MetaMask is not installed');
      }
    };

    initNetwork();

    return () => {
      if (window.ethereum && window.ethereum.removeListener) {
        window.ethereum.removeListener('chainChanged', handleNetworkChange);
      }
    };
  }, []);

  const handleNetworkChange = async (newChainId) => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${newChainId.toString(16)}` }],
        });
        setCurrentNetwork(newChainId);
        setNetworkConfig(getNetworkConfig(newChainId));
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setCurrentProvider(provider);
      } catch (error) {
        console.error('Failed to switch network:', error);
      }
    }
  };

  const fetchSupportedAssets = async () => {
    const token = localStorage.getItem('authToken');
    const response = await axios.get('https://api.divedao.io/api/get_supported_assets', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      setSupportedAssets(response.data);
      console.log(supportedAssets);
    } else {
      throw new Error('Failed to fetch supported assets');
    }
  };

  const fetchCexStatus = async () => {
    const token = localStorage.getItem('authToken');
    const response = await axios.get('https://api.divedao.io/api/get_cex_status', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (response.status === 200) {
      const { cex, status } = response.data;
      if (cex === null) {
        setShowSettingsModal(true); 
      } else {
        setShowSettingsModal(false);
        setCexName(cex);
        setCexStatus(status);
      }
    } else {
      throw new Error('Failed to fetch CEX status');
    }
  };

  const handleReAuth = async () => {
    const savedAddress = localStorage.getItem('walletAddress');
    if (savedAddress) {
      await authenticateWithWallet(savedAddress);
    } else {
      setShowWalletModal(true);
    }
  };

  const handleConnectWallet = async (address) => {
    setWalletConnected(true);
    setWalletAddress(address);
    setShowWalletModal(false);
    await authenticateWithWallet(address);
  };

  const authenticateWithWallet = async (address) => {
    try {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const nonce = Math.floor(Date.now() / 1000).toString();

        var trueAddress = ethers.utils.getAddress(address);
        console.log(trueAddress.length);

        const message = new SiweMessage({
          domain: window.location.origin,
          address: trueAddress,
          statement: 'Sign in with Ethereum to the app.',
          uri: window.location.origin,
          version: '1',
          chainId: await signer.getChainId(),
          nonce: nonce,
        });

        const signedMessage = await signer.signMessage(message.prepareMessage());

        const response = await axios.post('https://api.divedao.io/api/auth', {
          message: message.prepareMessage(),
          signature: signedMessage,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status !== 200) {
          throw new Error('Authentication failed');
        }

        const authData = response.data;
        console.log('Authentication successful:', authData);

        localStorage.setItem('authToken', authData.token);
        
        await fetchSupportedAssets();
        await fetchCexStatus();
        setIsConnected(true);
      } else {
        alert('Please install MetaMask!');
      }
    } catch (error) {
      console.error('Error connecting to MetaMask or authenticating:', error);
      alert('Failed to connect and authenticate. Please try again.');
    }
  };

  const handleDisconnectWallet = () => {
    setWalletConnected(false);
    setWalletAddress('');
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('authToken');
  };

  return (
    <AppContainer>
    <Header 
      walletConnected={walletConnected}
      walletAddress={walletAddress}
      onConnectClick={() => setShowWalletModal(true)}
      onDisconnectClick={handleDisconnectWallet}
      onSettingsClick={() => setShowSettingsModal(true)}
      onCurCex={(cexName)=>setCurrentCexName(cexName)}
      currentNetwork={currentNetwork}
      onNetworkChange={handleNetworkChange}
      supportedNetworks={supportedNetworks}
      cexName={cexName}
      cexStatus={cexStatus}
    />
    <MainContent>
      {walletConnected && currentProvider && isConnected && (
        <PositionInfo
          isConnected={isConnected}
          address={walletAddress}
          supportedAssets={supportedAssets}
          provider={currentProvider}
          networkConfig={networkConfig}
        />
      )}
    </MainContent>
    <WalletModal
      open={showWalletModal}
      onClose={() => setShowWalletModal(false)}
      onConnect={handleConnectWallet}
      setCurrentProvider={setCurrentProvider}
    />
    {isConnected && (
      <SettingsModal
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        cexName={currentCexName}
        onFinish={() => fetchCexStatus()}
      />
    )} 
  </AppContainer>
  );
}

export default App;
