// src/components/WalletModal.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { ethers } from 'ethers';

const WalletButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    &:hover {
      background-color: #e0e0e0;
    }
  }
`;

const WalletIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

function WalletModal({ open, onClose, onConnect, setCurrentProvider }) {
  const [isConnecting, setIsConnecting] = useState(false);

  const connectWallet = async (walletType) => {
    setIsConnecting(true);
    const { ethereum } = window;
    console.log(walletType, ethereum)
    try {
      let provider;
      if (ethereum && ethereum.isMetaMask && walletType === 'MetaMask') {
        
        provider = new ethers.providers.Web3Provider(ethereum);
      } else if (ethereum && ethereum.isRabby && walletType === 'Rabby') {
        provider = new ethers.providers.Web3Provider(ethereum);
      } else if (ethereum && ethereum.isUniswapWallet && walletType === 'Uniswap') {
        provider = new ethers.providers.Web3Provider(ethereum);
      } else if (ethereum) {
        const detectedWallets = [];
  
        if (ethereum.isMetaMask) detectedWallets.push('MetaMask');
        if (ethereum.isRabby) detectedWallets.push('Rabby');
        if (ethereum.isUniswapWallet) detectedWallets.push('Uniswap');
  
        // Inform the user if no matching wallet was detected
        if (detectedWallets.length === 0) {
          alert(`No supported wallets detected. Please install ${walletType} or enable an existing one.`);
          setIsConnecting(false);
          return;
        } else {
          // Wallet type not supported but a known wallet is installed
          alert(`Detected wallets: ${detectedWallets.join(', ')}, but they do not match the selected type: ${walletType}.`);
          setIsConnecting(false);
          return;
        }
      } else {
        alert('No Ethereum provider found. Please install a wallet!');
        setIsConnecting(false);
        return;
      }

      await provider.send("eth_requestAccounts", []);
  
      const accounts = await provider.listAccounts();
      const address = ethers.utils.getAddress(accounts[0]);
      localStorage.setItem('walletAddress', address);
      setCurrentProvider(provider);
      onConnect(address);
    } catch (error) {
      console.error(`Error connecting to ${walletType}:`, error);
      alert(`Failed to connect to ${walletType}. Please try again.`);
    } finally {
      setIsConnecting(false);
    }
  };
  

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Connect Wallet</DialogTitle>
      <DialogContent>
        <WalletButton onClick={() => connectWallet('MetaMask')} disabled={isConnecting}>
          <WalletIcon src="/metamask-icon.png" alt="MetaMask" />
          {isConnecting ? 'Connecting...' : 'MetaMask'}
        </WalletButton>
        <WalletButton onClick={() => connectWallet('Uniswap')} disabled={isConnecting}>
          <WalletIcon src="/uniswap-icon.png" alt="Uniswap" />
          {isConnecting ? 'Connecting...' : 'Uniswap Wallet'}
        </WalletButton>
        <WalletButton onClick={() => connectWallet('Rabby')} disabled={isConnecting}>
          <WalletIcon src="/rabby-icon.png" alt="Rabby" />
          {isConnecting ? 'Connecting...' : 'Rabby Wallet'}
        </WalletButton>
      </DialogContent>
    </Dialog>
  );
}

export default WalletModal;
