import React, { useEffect } from 'react';
import styled from 'styled-components';

const TokenContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const TokenImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const TokenLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const TokenDetails = ({ tokenId, symbol, network }) => {

  return (
    <TokenContainer>
      <TokenLink href={`${network.url}${tokenId}`} target="_blank" rel="noopener noreferrer">
        {symbol}
      </TokenLink>
    </TokenContainer>
  );
};

export default TokenDetails;
