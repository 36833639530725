// src/components/Header.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Menu, MenuItem, Select, Tooltip  } from '@mui/material';
import { AccountBalance as CexIcon } from '@mui/icons-material';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between; /* Title on the left, other elements on the right */
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const CexIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2em;

`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const WalletButton = styled(Button)`
  && {
    background-color: #3f51b5;
    color: white;
    &:hover {
      background-color: #303f9f;
    }
  }
`;

const NetworkSelect = styled(Select)`
  && {
    min-width: 120px;
    margin-right: 20px; /* Added margin for spacing */
    height: 40px; /* Set height to match buttons */
  }
`;

const CexSelect = styled(Select)`
  && {
    min-width: 120px;
    margin-right: 20PX; /* Added margin for spacing */
    height: 40px; /* Set height to match buttons */
  }
`;



function Header({ walletConnected, walletAddress, onConnectClick, onDisconnectClick, onSettingsClick, onCurCex, currentNetwork, onNetworkChange, supportedNetworks, cexName, cexStatus }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const supportedCexes = [
    { name: 'bybit', status: cexName === 'bybit' ? cexStatus : null },
    { name: 'binance', status: cexName === 'binance' ? cexStatus : null },
  ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = (cex) => {
    onCurCex(cex);
    onSettingsClick(true); 
    setAnchorEl(null);
  };

  return (
    <HeaderContainer>
      <Title>Liquidity Hedging</Title>
      <RightSection>
      {walletConnected ? (
        <>
          <CexSelect 
            value={cexName} 
            onChange={() => {}}
            style={{
              color: cexStatus === 'active' ? 'green' : 'red', 
            }}
          >
            {supportedCexes.map((cex) => (
              <MenuItem
                key={cex.name}
                value={cex.name}
                onClick={() => handleSettingsClick(cex.name)}
                style={{
                  color: cex.name === cexName ? (cexStatus === 'active' ? 'green' : 'red') : 'inherit',
                }}
              >
                {cex.name}
              </MenuItem>
            ))}
          </CexSelect>
          
          <NetworkSelect
            value={currentNetwork}
            onChange={(e) => onNetworkChange(e.target.value)}
          >
            {supportedNetworks.map((network) => (
              <MenuItem key={network.chainId} value={network.chainId}>
                {network.name}
              </MenuItem>
            ))}
          </NetworkSelect>
          
          <WalletButton onClick={handleMenuOpen}>
            {walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}
          </WalletButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem disabled onClick={handleMenuClose}>Settings</MenuItem>
            <MenuItem onClick={() => { onDisconnectClick(); handleMenuClose(); }}>Log out</MenuItem>
          </Menu>
        </>
      ) : (
        <WalletButton onClick={onConnectClick}>Connect Wallet</WalletButton>
      )}
      </RightSection>
    </HeaderContainer>
  );
}

export default Header;